let menuButton = (event) => { 
	event.preventDefault(); 
	$('body').addClass('menu-active');
	$('#Site').prepend('<div id="blocker"></div>');
	$('#blocker').one('click', closemenu);
};
let closemenu = (event) => {
	event.preventDefault(); 
	$('body').removeClass('menu-active');
	$('#blocker').off('click');
	$('#blocker').remove();
};	 

let goToByScroll = (event, id) => { 
	$('html,body').animate({scrollTop: $(id).offset().top},'slow');
	closemenu(event);
}

$('#menuButton').on('click', menuButton);
$('#closeMenuButton').on('click', closemenu);
$('.menu-link').on('click', function(event) {
	let href = $(this).attr('href');
	goToByScroll(event, href)	
});


