const Parallax = require('parallax-js');


if ($('body').hasClass('home')) {
	
	var scene = document.getElementById('scene');

	var parallaxInstance = new Parallax(scene, {
		pointerEvents: true,
		onReady: function(){
		}
	});
}
