$('.read-more--button').on('click', function(event) {
	event.preventDefault();
	let text = $(this).text().toLowerCase();
	$(this).parent().parent().toggleClass('full-height');
	if (text == 'read more') {
		$(this).text('close');
	} else {
		$(this).text('read more');
	}
}); 




$(window).on('scroll', function() {
    var scrollTop = $(this).scrollTop();

    var $star = $('#nbhStar');
    var $footer = $('#nbhStar');
    var topDistance = $star.offset().top;
    var footerDistance = $footer.offset().top + $(window).height();
    if ( (topDistance+100) < scrollTop ) {
        $('#footerStar').addClass('visible');
    } else {
        $('#footerStar').removeClass('visible');
    }

 	if($(window).scrollTop() + $(window).height() == $(document).height()) {
       $('#footerStar').addClass('star-bottom');
    } else {
			$('#footerStar').removeClass('star-bottom');
    }


});