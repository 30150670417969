(function ($, window, document, undefined) {

	if($('body').hasClass('home')){
		const Menu = require('./_modules/menu');
		const Swipers = require('./_modules/swiper');  
		const Posters = require('./_modules/poster');    
		const FrontPage = require('./_modules/front-page');
	}
	const Trailer = require('./_modules/trailer');
	const Articles = require('./_modules/articles');    


})($, window, document);     