var iframe = document.querySelector('iframe');
var player = new Vimeo.Player(iframe);
var trailerActive = new Event('trailerActive');
var trailerInactive = new Event('trailerInactive');
const body = document.querySelector('body');

const trailerButton = (event) => {
	event.preventDefault();
	$('body').removeClass('menu-active');
	$('#blocker').off('click');
	$('#blocker').remove();  
	$('body').addClass('trailer-active');
	body.dispatchEvent(trailerActive);
	player.play();
	$('#trailer').on('click', () => { 
		$('body').removeClass('trailer-active');
		player.pause();
		body.dispatchEvent(trailerInactive);
	});

};

$('#trailerButton').on('click', trailerButton);
$('#trailerButtonMenu').on('click', trailerButton);
$('#trailerButtonPoster').on('click', trailerButton);