const Swiper = require('swiper');
const filmStills = new Swiper('.swiper-container--film', {
	speed: 400,
	spaceBetween: 0, 
	simulateTouch: false,
	preloadImages: false,
	lazy: true,
	loadPrevNext: true,
	loadPrevNextAmount: 2,
	pagination: {
		el: '.swiper-pagination--film',
		type: 'bullets',
		clickable: true, 
		dynamicBullets: true, 
		dynamicMainBullets: 5
	},
	navigation: {
		nextEl: '.swiper-button-next--custom--film',
		prevEl: '.swiper-button-prev--custom--film',
	},
	on: {    
		slideChange: function(){ 
			let currentCaption = $(this.slides[this.activeIndex]).data('caption');
			$('.swiper-caption--film').text($.trim(currentCaption));
		}
	}
});

const exhibitonStills = new Swiper('.swiper-container--exhibition', {
	speed: 400,
	spaceBetween: 0, 
	simulateTouch: false,
	preloadImages: false,
	lazy: true,
	loadPrevNext: true,
	loadPrevNextAmount: 2,
	pagination: {
		el: '.swiper-pagination--exhibition',
		type: 'bullets',
		clickable: true, 
		dynamicBullets: true, 
		dynamicMainBullets: 5
	},
	navigation: { 
		nextEl: '.swiper-button-next--custom--exhibition',
		prevEl: '.swiper-button-prev--custom--exhibition',
	},
	on: {    
		slideChange: function(){ 
			let currentCaption = $(this.slides[this.activeIndex]).data('caption');
			$('.swiper-caption--exhibition').text($.trim(currentCaption));
		}
	}
});

const Contributors = new Swiper('.swiper-container--contributors', {
	speed: 400,
	spaceBetween: 0, 
	simulateTouch: false,
	pagination: {
		el: '.swiper-pagination--contributors',
		type: 'bullets',
		clickable: true, 
		dynamicBullets: true, 
		dynamicMainBullets: 5
	},
	navigation: {
		nextEl: '.swiper-button-next--custom--contributors',
		prevEl: '.swiper-button-prev--custom--contributors',
	}
});


const posterStills = new Swiper('.swiper-container--poster', {
	speed: 200,
	effect: 'fade',
	loop: true,
	spaceBetween: 0, 
	simulateTouch: false,
	autoplay: {
	  delay: 1000,
	},
	on: {
		slideChange: function(){
			if ($(this.slides[this.activeIndex]).hasClass('yellow')) {
				$('#leftBlock').removeClass('yellowChange');
				$('#rightBlock').removeClass('yellowChange');
			} else {
				$('#leftBlock').addClass('yellowChange');
				$('#rightBlock').addClass('yellowChange');
			}
		}
	}
});


$('body').on('trailerActive', function(event) {
	if (!$(posterStills.slides[posterStills.activeIndex]).hasClass('yellow')) {
		posterStills.slideNext();
	}
	posterStills.autoplay.stop();
});

$('body').on('trailerInactive', function(event) {
	posterStills.autoplay.start();
});